<template>
	<div class="pass">
		<div class="title">用户信息</div>
		<div class="conent">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px">
				<el-form-item label="旧密码" prop="password">
					<el-input v-model="form.password" show-password style="width: 240px;"></el-input>
				</el-form-item>
				<el-form-item label="新密码" prop="newPassword">
					<el-input v-model="form.newPassword" show-password style="width: 240px;"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="newPasswords">
					<el-input v-model="form.newPasswords" show-password style="width: 240px;"></el-input>
				</el-form-item>
			</el-form>
			<div class="buttons" @click="submitForm('form')">确认修改</div>
		</div>
	</div>
</template>

<script>
	import { putpassword } from "@/request/xie";
	export default{
		data(){
			let newpassword=(rule, value, callback)=>{
				if (value === '') {
					callback(new Error('请输入新密码'));
				} else {
					let regex=/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/
					if(!regex.test(this.form.newPassword)){
						this.$message.error('密码必须包含字母和数字,且在6~12位之间')
						return false
					}
					callback();
				}
			}
			let newpasswords=(rule, value, callback)=>{
				if (value === '') {
					callback(new Error('请确认密码'));
				} else {
					if(value!==this.form.newPassword){
						this.$message.error('两次输入的密码不一致')
						return false
					}
					callback();
				}
			}
			return{
				form:{
					password:'',
					newPassword:'',
					newPasswords:''
				},
				rules:{
					password:{required:true,message: '请输入旧密码', trigger: 'blur' },
					newPassword:{required:true,validator: newpassword, trigger: 'blur' },
					newPasswords:{required:true,validator: newpasswords, trigger: 'blur' }
				}
			}
		},
		methods:{
			//提交
			submitForm(formName){
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let data={
							password:this.$md5(this.form.password),
							newPassword:this.$md5(this.form.newPassword),
						}
						putpassword(data).then((res)=>{
							if(res.code==200){
								this.$message.success('修改成功')
								localStorage.clear()
								setTimeout(() => {
								   window.location.href = "/Home";
								}, 1000);
							}
						})
					}
				});
			}
		}
	}
</script>

<style scoped lang="scss">
	.pass{
		background-color: #fff;
		.title{
			color: #535353;
			font-size: 18px;
			padding: 25px 0 20px 42px;
			box-sizing: border-box;
			border-bottom: 1px solid #F3F3F3;
			color: #535353;
		}
		.conent{
			margin-top: 35px;
			padding-left: 28px;
			padding-bottom: 30px;
		}
		.buttons{
			cursor: pointer;
			width: 112px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			color: #fff;
			font-size: 16px;
			background-color: #00A3E0;
			border-radius: 3px;
			margin-left: 82px;
		}
	}
</style>
